import { EXHIBITOR_BASE_FRAGMENT } from '@/graphql/_Fragments/Exhibitor/Base';
import { LARGE_PRODUCT_IMAGE_BASE_FRAGMENT } from '@/graphql/_Fragments/LargeProductImage/Base';

export const LARGE_PRODUCT_CARD_FRAGMENT = `
  fragment largeProductCardFragment on LargeProduct {
    id
    uid
    schemaCode
    name
    featured
    description
    images(orderBy: [id_asc]) {
      ...largeProductImageBaseFragment
    }
    exhibitor {
      ...exhibitorBaseFragment
    }
    _isBookmarked(myUid: "%authUser%")
    _isRecommendedForMe
    _isExhibitorFeatured
  }
  ${LARGE_PRODUCT_IMAGE_BASE_FRAGMENT}
  ${EXHIBITOR_BASE_FRAGMENT}
`;
